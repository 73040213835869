.priceSlider {
  --color: theme("colors.vollblue.700");
}

.priceSlider_content {
  border: 1px solid theme("colors.black");
  padding: 1.5rem;
  margin: auto;

  @screen lg {
    padding: 2rem;
  }

  @screen lg {
    max-width: 796px;
  }

  @screen xl {
    max-width: 992px;
  }

  @screen 2xl {
    max-width: 880px;
  }

  @screen 3xl {
    max-width: 1048px;
  }
}

.headline {
  margin-top: 0;
  margin-left: 0;
}

.interactive {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  @screen sm {
    flex-direction: column;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;

  @screen sm {
    padding: 0;
    margin: 0;
    border: none;
    margin-bottom: 3rem;
    position: relative;

    /* Min/Max Values */
    & span {
      position: absolute;
      transform: translateY(100%);

      &:first-of-type {
        left: 0;
      }

      &:last-of-type {
        right: 0;
      }
    }
  }

  & label {
    margin-bottom: 1rem;
    @apply text-sm;
  }
}

.sliderWrapper {
  display: flex;
  align-items: center;
}

.input {
  flex: 1;
  margin-inline: 0.75rem;

  -webkit-appearance: none;
  border: 1px solid theme("colors.black");
  width: 100%;
  height: 0.75rem;
  border-radius: 999px;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    appearance: none;
    border: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--color);
    cursor: pointer;
  }

  &::-moz-range-thumb {
    -webkit-tap-highlight-color: transparent;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    background: var(--color);
    cursor: pointer;
  }

  &:focus {
    &::-webkit-slider-thumb {
      @apply border-2 border-black border-solid;
    }
  }

  @screen sm {
    margin: 0;
  }
}

.resultWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  flex-shrink: 0;
  margin-top: 2rem;
}

.resultOverline {
  line-height: 1;
}

.result {
  color: var(--color);
  line-height: 1;
  font-size: 1.25rem;

  white-space: nowrap;
  width: 7ch;

  font-variant-numeric: tabular-nums;

  @screen sm {
    line-height: 1.25;
    font-size: 2.5rem;
    width: auto;
  }
}
