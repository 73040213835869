.blockQuote {
  @apply flex flex-col-reverse sm:flex-row pl-0;
  @apply gap-4 md:gap-6 lg:gap-10 xl:gap-12 2xl:gap-16 3xl:gap-20;
}

.blockQuoteRight {
  @apply sm:flex-row-reverse;

  .marks {
    @apply sm:left-auto right-0;
    width: 11.75rem;

    @screen sm {
      transform: scaleX(-1);
    }
  }

  .quote {
    @apply sm:text-right;
  }
}

.noImage {
  .marks {
    @apply sm:hidden;
  }

  .imageWrapper {
    @apply aspect-auto rounded-none hidden sm:block h-full;
  }
}

.cite {
  @apply relative flex flex-row items-start sm:flex-col gap-2 sm:gap-4 w-full sm:w-1/3 lg:w-1/4;
}

.content {
  @apply relative sm:w-2/3 lg:w-3/4;
}

.placeholder {
  @apply w-full flex flex-nowrap gap-1 sm:gap-4 self-end h-full;

  > * {
    transform: scaleX(-1);
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.authorWrapper {
  @apply sm:w-1/2 sm:w-full break-words hyphens;
}

.author {
  @apply block mb-1;
}

.position {
  @apply block text-black-1000;
}

.quote {
  @apply text-black-1000 relative z-10;
  margin-top: 6rem;
}

.imageWrapper {
  min-width: 5rem;
  max-width: 6rem;

  @apply block w-1/3 sm:w-1/2 sm:min-w-0 sm:max-w-full sm:w-full aspect-square relative img-radius;

  & img {
    object-fit: cover;
  }
}

.marks {
  @apply flex flex-nowrap gap-4 absolute top-0 left-0 ;
  width: 11.75rem;
  transform: scaleX(1);
  svg {
    height: 100%;
    width: 100%;
  }
}

.text {
  > p:last-child {
    @apply mb-0;
  }
}
