.permutation {
  --permutation-size: 50rem;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: darken;

  /*animation: move 1s infinite linear;*/
}

.grecaptcha-badge {
  visibility: visible;
}


@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: var(--permutation-size) 0;
  }
}

.inputFields {
  @apply w-full block border-gray-500 rounded-md shadow-sm sm:focus:ring-black focus:border-black;
}

.radioButtons {
  @apply w-4 h-4 border-gray-500 focus:ring-black text-black;
}

.submitButton {
  @apply border-gray-500 focus:border-black ml-5 mb-6 bg-vollyellow-700;

  &:after {
    @apply bg-white
  }

  &:hover,
  &:active {
    @apply text-black bg-white
  }

  &:global(.loading) {
    @apply bg-white cursor-not-allowed animate-pulse;
  }
}

.modalButton {
  @apply border-black focus:border-black;
}


.modalContent {
  @apply fixed top-1/2 left-1/2 inline-block;
  @apply px-10 py-12 text-left text-black-1000;
  @apply border border-black rounded-lg;
  transform: translate(-50%, -50%);
  min-width: calc(100vw - 6rem);

  p {
    @apply mb-8;
  }

  @screen sm {
    min-width: auto;
  }
}

.successModalOverlay {
  @apply fixed inset-0 bg-gray-600 opacity-75;
}

.successModalContent {
  @apply bg-vollgreen-700;
}

.errorModalOverlay {
  @apply fixed inset-0 bg-gray-600 opacity-75;
}

.errorModalContent {
  @apply bg-vollmagenta-700;
}

.iconButton {
  @apply w-4 h-4 inline-flex items-center justify-center;
  @apply text-black-1000 absolute cursor-pointer;
  border-radius: 100%;
  top: 1rem;
  right: 1rem;
}

.vector {
  @apply flex absolute -z-10;
  @apply -right-4 bottom-4;
}

.contactWrapper {
  @apply container flex flex-wrap justify-center gap-4;

  .introWrapper {
    @apply relative flex flex-col justify-center;
    @apply mt-32 xl:mt-0;
    @apply max-w-md sm:max-w-lg mx-auto lg:mx-0;

    .title {
      @apply text-4xl sm:text-7xl;
      @apply font-extrabold tracking-tight;
    }

    .copy {
      @apply text-gray-500 mt-4 sm:mt-3;
    }

    .imageWrapper {
      @apply w-full flex flex-wrap md:flex-nowrap relative items-end;
      @apply text-gray-500 p-4 mt-16;

      .contactImage {
        @apply rounded-3xl;
        @apply h-full;
      }
    }
  }

  .formWrapper {
    @apply relative py-16 pr-4 sm:py-24 sm:px-6 lg:px-8 lg:pr-8;
    @apply lg:max-w-7xl lg:py-32;
    @apply max-w-md mx-auto sm:max-w-lg lg:mx-0;
  }
}
