.headline {
  @apply mb-12 sm:mb-20;
}

.blockQuotes {
  @apply pl-0;

  & > :not(:only-child) {
    @apply mb-20 md:mb-28 xl:mb-32 2xl:mb-40;
  }

  & > :last-child {
    @apply mb-0;
  }
}

.wrapper {
  @apply sm:raster-12 md:raster-10;
}

.sliderControls {
  @apply w-full flex justify-end;
  @apply mt-12;
}

.prevButton,
.nextButton {
  @apply m-2 text-black border-black;

  &.highlight {
    &:after {
      background: theme("colors.black");
      transform: translate3d(0, 100%, 0);
      transition: all 0.3s;
    }

    &:hover,
    &:active {
      border-color: theme("colors.black");
      color: theme("colors.white");

      &:after {
        transform: translate3d(0, 0, 0) skewY(15deg) scale(1, 3);
      }
    }
  }
}
