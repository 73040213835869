.homeWrapper {
  @apply relative;
}

.headlineWrapper {
  @apply container flex relative flex-col justify-center min-h-screen;
}

.letterL {
  transform: scale(-1, 1);
}

.letterLWrapper {
  @apply absolute;
  /* super smol */
  @apply w-2/3 md:w-1/2 right-0;

  @screen md {
    @apply w-1/2 right-0;
    transform: translateX(-25%);
  }

  @screen lg {
    @apply w-1/3 right-0;
    transform: translateX(-50%);
  }
}

.letterOWrapper {
  @apply absolute;
  /* super smol */
  @apply w-3/4 right-0 top-1/2;
  transform: translate(66.666666666666%, -65%);

  @screen md {
    @apply w-1/2 right-0 top-1/2;
    transform: translate(50%, -25%);
  }

  @screen lg {
    @apply w-1/3 right-0 top-1/2;
    transform: translate(33.3333333333333333%, -25%);
  }
}

.letterVWrapper {
  @apply absolute;
  /* super smol */
  @apply bottom-40 -left-32 w-5/6;

  @screen md {
    @apply bottom-20 left-0 w-2/3;
  }

  @screen lg {
    @apply bottom-0 left-0 w-1/2;
  }
}

.text {
  @apply mt-4;
}
