.teaserLinkWrapper {
  @apply relative no-underline;
}

@screen lg {
  .teaserLinkWrapper:hover {
    .bg {
      width: calc(100% + 4rem);
    }
  }

  .withImage:hover {
    .bg {
      height: calc(100% + 8rem);

      @screen xl {
        height: calc(100% + 16rem);
      }
    }
  }

  .withoutImage:hover {
    .bg {
      height: calc(100% + 2rem);
    }
  }
}

.imageWrapper {
  @apply relative w-full img-radius;
  aspect-ratio: 3/4;
}

.image {
  @apply z-10;
  @apply object-cover object-center;
}

.inner {
  @apply relative grid grid-cols-1 grid-rows-1;
}

.content {
  @apply row-start-1 col-start-1 mt-12;
}

.headline {
  @apply flex items-end pb-4 last:mb-0;
  @apply h-32;
  @apply sm:h-64;
  @apply lg:h-32;
  @apply xl:h-64;
}

.bg {
  @apply row-start-1 col-start-1;
  @apply w-32 h-32;
  @apply sm:w-64 sm:h-64;
  @apply lg:w-32 lg:h-32;
  @apply xl:w-64 xl:h-64;
  @apply transition-all duration-300 ease-in-out;
  @apply rounded-3xl xl:rounded-4xl;
}

.text {
  > p:last-child {
    @apply mb-0;
  }
}
