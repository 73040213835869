.partnersWrapper {
  @apply container mb-0;
}

.marquee {
  --gap: 1rem;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);

  &:hover .marquee__content {
    animation-play-state: paused;
  }

  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;

    gap: var(--gap);
    animation: scroll 60s linear infinite;

    li::marker {
      content: "";
    }

    .imageWrapper {
      @apply block my-auto w-56 h-auto;

      .image {
        @apply block max-w-full max-h-full object-contain;
      }
    }
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - var(--gap)));
    }
  }
}
